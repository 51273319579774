import * as fb from "./firebase";
import store from "./store";
import schema_validator from "./validator";

export type * as _Global from "./_model";
export const global = {
  fb,
  store,
  schema_validator,
};
